@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.awards {
  $block-name: &;

  padding-top: 50px;

  @include mq($to: md) {
    padding-top: 25px;
  }

  .rfm-marquee-container {
    overflow: visible;
  }

  .rfm-marquee {
    // padding-left: 20px;
    // padding-right: 20px;
  }

  &__cell {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 200px;
    font-size: 0;
    line-height: 0;
  }

  &__image {
    flex: 0 0 auto;
    margin-right: 8px;
    opacity: 0.5;
    filter: grayscale(100%);
  }

  &__grid {
    gap: 40px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    padding-left: 20px;
    padding-right: 20px;

    @include mq($to: md) {
      padding-left: 5px;
      gap: 10px;
      padding-right: 5px;
    }
  }

  &__title {
    color: $dark-gray-400;
    font-family: $font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
}
